<template>
  <!--begin::Advance Table Widget 9-->
  <div class="">
    
    <MemberEditorCompactModal
      ref="member-editor-compact-modal-tab"
      :member_id="member_id"

      @created="member_created"
      @updated="member_updated"
      @deleted="member_deleted"
    />

    <InvoiceEditorModal 
      ref="invoice-editor-modal"

      :item="selected_item"
    />


    <b-row class="mt-2 mr-2">
      <b-col cols="12">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100">
          <button class="btn btn-primary font-weight-bolder font-size-sm mr-4" :disabled="!can_invoice" @click.prevent="email_sendout_clicked">
            <i class="menu-icon flaticon2-file"></i>
            {{$t('INVOICE.EMAIL_SENDOUT')}}
          </button>

          <button class="btn btn-primary font-weight-bolder font-size-sm" :disabled="!can_invoice" @click.prevent="create_pdf_clicked">
            <i class="menu-icon flaticon2-file"></i>
            {{$t('INVOICE.CREATE_PDF')}}
          </button>
        </div>
        
      </b-col>

    </b-row>

    <b-row class="mr-2">
      <b-col cols="12">
        <p class="error-info-text" style="text-align: right;" v-if="!can_invoice">{{ $t('INVOICE.CAN_INVOICE_ERROR') }}</p>
      </b-col>
      
    </b-row>

    <div class="card-body pt-0 table-responsive" style="overflow: visible;">

      <b-row>
        <b-col xl="3" md="6" sm="12">
          <b-form-group
            id="input-group-created_from"
            :label="$t('PAGES.RENEWS.CREATED_FROM')"
            label-for="input-created_from"
          >
            <memlist-date-picker v-model="filters.created_from"></memlist-date-picker>
          </b-form-group>
        </b-col>
        <b-col xl="3" md="6" sm="12">
          <b-form-group
            id="input-group-created_to"
            :label="$t('PAGES.RENEWS.CREATED_TO')"
            label-for="input-created_to"
          >
            <memlist-date-picker v-model="filters.created_to"></memlist-date-picker>
          </b-form-group>
        </b-col>
        <b-col xl="3" md="6" sm="12">
          <b-form-group
            :label="$t('INVOICE.PAYMENT_STATUS')"
            >
            <b-form-select
              id="status-filter"
              class="mr-sm-2 mx-0"
              v-model="filters.payment_status"
              :options="payment_options"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col xl="3" md="6" sm="12">
          <b-form-group
            :label="$t('INVOICE.PRINTED')"
            >
          <b-form-select
            id="status-filter"
            class="mx-0"
            v-model="filters.printed"
            :options="printed_options"

          />
          </b-form-group>
        </b-col>
      </b-row>


      <b-row>
        <b-col xl="3" md="6" sm="12">
          <b-form-group
            id="input-group-kivra_status"
            :label="$t('INVOICE.KIVRA_STATUS')"
            label-for="input-kivra_status"
          >
          <b-form-select
              id="status-filter"
              class="mr-sm-2 mx-0"
              v-model="filters.kivra_status"
              :options="kivra_status_options"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col lg="6" sm="12">
          <b-form-input
            id="filter-input"
            v-model="filters.text"
            type="search"
            style="max-width:621px"
            :placeholder="$t('COMMON.SEARCH')"
          ></b-form-input>
        </b-col>
        <b-col lg="6" sm="12">
          <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100">

            <b-form-select
              class="mr-sm-2 mt-2 align-bottom"
              style="max-width: 100px;"
              :options="[100, 500, 1000]"
              v-model="per_page"
            />

            <b-pagination
              class="mb-0 mt-2 align-bottom"
              v-model="current_page"
              :total-rows="totalRows"
              :per-page="per_page"
              aria-controls="invoice-table"
              first-number
              last-number
            ></b-pagination>
          </div>
        </b-col>
      </b-row>

      <b-table
        id="invoice-table"
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        :fields="fields"
        :filter="filters"
        :items="ajax_pagination"
        :per-page="per_page"
        :current-page="current_page"
        head-variant="light"
        ref="invoiceTable"
        @filtered="onFiltered"
        @sort-changed="onSortChanged"
      >
        <template #cell(payment_status)="row">
          <v-chip
            style="font-size: 10px; color: red; border-color: red;"
            v-if="row.item.payment_status === 'NOT_PAID'"
            color="red"
            outlined
          >
            {{ $t('INVOICE.PAYMENT_STATUSES.NOT_PAID') }}
          </v-chip>

          <v-chip
            style="font-size: 10px; color: green; border-color: green;"
            v-else-if="row.item.payment_status === 'PAID'"
            color="green"
            outlined
          >
            {{ $t('INVOICE.PAYMENT_STATUSES.PAID_IN_FULL') }}
          </v-chip>

          <v-chip
            style="font-size: 10px;"
            v-else
            color="blue"
            outlined
          >
            {{ $t('INVOICE.PAYMENT_STATUSES.' + row.item.payment_status) }}
          </v-chip>
        </template>

        <template #cell(printed)="row">
          <v-chip
            style="font-size: 10px; color: green; border-color: green;"
            v-if="row.item.printed"
            color="red"
            outlined
          >
            {{ $t('COMMON.YES') }}
          </v-chip>

          <v-chip
            style="font-size: 10px; color: red; border-color: red;"
            v-else
            color="red"
            outlined
          >
            {{ $t('COMMON.NO') }}
          </v-chip>

        </template>


        <template #cell(actions)="row">
          <div class="justify-content-end d-flex">
            <a v-if="row.item.member" class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="member_selected(row.item.member.member_id)">
              <span class="svg-icon svg-icon-md text-primary">
                <i class="fa fa-user color-primary"></i>
              </span>
            </a>
            <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="edit_invoice_selected(row.item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a
              v-if="!row.item.printed"
              href="#"
              class="btn btn-icon btn-light btn-sm mx-3"
              @click.prevent="print_invoice_clicked(row.item.invoice_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/print-solid.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mr-3"
              @click.prevent="download_invoice_clicked(row.item.invoice_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>
            
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm"
              @click.prevent="delete_invoice_clicked(row.item.invoice_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>
          </div>
        </template>

        <template #cell(selected)="row">
          <!--<v-simple-checkbox
            v-model="row.item.selected"
          ></v-simple-checkbox>-->
        </template>

        
      </b-table>
      <b-row class="mt-8">
        <b-col><span>{{ $t('INVOICE.NUM_ROWS') }}: {{totalRows}}</span></b-col>
      </b-row>
      <!--end::Table-->
    </div>

    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import dayjs from 'dayjs';

import { mapGetters } from 'vuex';
import isBetween from 'dayjs/plugin/isBetween';

import InvoiceEditorModal from '@/view/pages/ml/invoices/InvoiceEditorModal.vue';

dayjs.extend(isBetween);

export default {
  name: 'InvoiceTable',
  mixins: [ toasts ],
  components: {

    InvoiceEditorModal
  },
  emits: [
    'createInvoiceClicked',
    'print_invoice_clicked',
    'download_invoice_clicked',
    'downloadMultipleInvoicesClicked',
    'email_sendout_clicked',
    'create_pdf_clicked',
    'downloadAllInvoicesClicked',
    'selectInvoiceClicked',
    'delete_invoice_clicked',
    'submit_update'
  ],
  computed: {
    ...mapGetters(['currentPeriodId', 'periods']),
    currentPeriod() {
      return this.periods.find(period => period.id === this.currentPeriodId);
    }
  },
  mounted() {

  },
  watch: {
    'filters.created_from'() {
      const payment_status = this.filters.payment_status ? this.filters.payment_status : null;
      this.$emit('filters_changed', { payment_status: payment_status, created_from: this.filters.created_from, created_to: this.filters.created_to, printed: this.filters.printed });
    },
    'filters.created_to'() {
      const payment_status = this.filters.payment_status ? this.filters.payment_status : null;
      this.$emit('filters_changed', { payment_status: payment_status, created_from: this.filters.created_from, created_to: this.filters.created_to, printed: this.filters.printed });
    },
    'filters.payment_status'() {
      const payment_status = this.filters.payment_status ? this.filters.payment_status : null;
      this.can_invoice = payment_status === 'NOT_PAID';
      this.$emit('filters_changed', { payment_status: payment_status, created_from: this.filters.created_from, created_to: this.filters.created_to, printed: this.filters.printed });
    },
    'filters.printed'() {
      const payment_status = this.filters.payment_status ? this.filters.payment_status : null;
      this.$emit('filters_changed', { payment_status: payment_status, created_from: this.filters.created_from, created_to: this.filters.created_to, printed: this.filters.printed });
    },
    currentPeriod() {

    }
  },
  methods: {

    edit_invoice_selected(item) {
      this.selected_item = item;

      this.$nextTick(()=>{
        this.$refs['invoice-editor-modal'].show();
      });
    },

    member_created(item) {},

    member_updated(item) {},

    member_deleted(item) {},

    onSortChanged(sortChanged) {

      if (sortChanged.sortBy === '') {
        this.orderBy = undefined;
      }
      else {
        this.orderBy = {

        };

        this.orderBy[sortChanged.sortBy] = sortChanged.sortDesc ? 'desc' : 'asc';
      }
    },


    get_options() {

      const options = {};

      if (this.filters.payment_status) {
        options.payment_status = this.filters.payment_status;
      }

      if (this.filters.printed !== null && this.filters.printed !== undefined) {
        options.printed = this.filters.printed;
      }

      if (this.filters.created_from) {
        options.created_from = this.filters.created_from;
      }

      if (this.filters.created_to) {
        options.created_to = this.filters.created_to;
      }

      if (this.filters.text !== '') {
        options.text = this.filters.text;
      }

      console.log('get_options', this.filters, options)

      return options;
    },


    async get_invoice_ids() {

      try {
        const options = this.get_options();

        const response = await axios.post(`/invoice/invoice_ids`, {
          options: options
        });

        if (response.status === 200) {
          return response.data;
        }

        throw `invalid status ${err}`;
      }
      catch (err) {
        throw `get_invoice_ids error ${err}`;
      }

    },


    // items are fetched here whenever pagination ahas changed or
    // by forcing reload via: this.$refs['invoiceTable'].refresh();
    ajax_pagination: function(ctx, callback) {


      const vm = this;
      let params = {
        pageNo: ctx.current_page,
        pageSize: ctx.per_page,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_page_count();

      const options = this.get_options();

      axios
        .post(`/invoice/pagination`, {
          options: options,
          orderBy: this.orderBy,
          page: this.current_page,
          limit: this.per_page
        })
        .then(res => {
          var arr = res.data;

          let i = 0;
          for (const a of arr) {
            a.index = i++;
            a.selected = this.copy_rows.findIndex(id => id === a.shop_order_id) >= 0;
          }

          this.copy_rows = arr;

          callback(arr || []);
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_GET_DATA'));
          console.error('pagination get error', err);
        });

      return null;
    },


    get_page_count() {

      const options = this.get_options();

      axios
        .post(`/invoice/pagination/count?limit=${this.per_page}&page=${this.current_page}`, { options: options })
        .then(res => {
          this.totalRows = res.data.count;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.UNABLE_GET_NUM_ORDERS'));
        });
    },

    member_selected(member_id) {
      this.member_id = member_id;
      this.$refs['member-editor-compact-modal-tab'].show(member_id);
    },

    onRowSelected(items) {
      this.selected = items;
    },

    getStatus(status) {
      return {
        PAID: this.$t('INVOICE.PAID'),
        NOT_PAID: this.$t('INVOICE.NOT_PAID'),
        PARTIALLY_PAID: this.$t('INVOICE.PARTIALLY_PAID')
      }[status];
    },
    onFiltered(filteredItems) {
      this.filteredItems = filteredItems;
      this.allSelected = false;
    },
    createInvoiceClicked() {
      this.$emit('createInvoiceClicked');
    },
    print_invoice_clicked(invoice_id) {
      this.$emit('print_invoice_clicked', invoice_id);
    },
    download_invoice_clicked(invoice_id) {
      this.$emit('download_invoice_clicked', invoice_id);
    },
    downloadMultipleInvoicesClicked() {
      this.$emit('downloadMultipleInvoicesClicked', this.selected);
      this.$refs.invoiceTable.clearSelected();
    },
    email_sendout_clicked() {
      this.$emit('email_sendout_clicked', this.get_options(), this.totalRows);
    },
    create_pdf_clicked() {
      this.$emit('create_pdf_clicked', this.get_options(), this.totalRows);
    },
    downloadAllInvoicesClicked() {
      this.$emit('downloadAllInvoicesClicked', this.filteredItems);
    },
    selectInvoiceClicked(invoice_id) {
      this.$emit('selectInvoiceClicked', invoice_id);
    },
    delete_invoice_clicked(invoice_id) {
      this.$emit('delete_invoice_clicked', invoice_id);
    },
    submit_update(invoice_id) {
      this.$emit('submit_update', invoice_id, this.paymentDropdowns[invoice_id].value);
    },
    updatePaymentStatus(invoice_id, value) {
      let newStatus = JSON.parse(JSON.stringify(this.paymentDropdowns));

      newStatus[invoice_id] = value;

      this.paymentDropdowns = newStatus;
    }
  },
  data() {
    return {
      selected_item: {},
      member_id: null,
      totalRows: 0,
      orderBy: { created_at: 'desc' },
      allSelected: false,
      can_invoice: false,
      current_page: 1,
      per_page: 100,
      options: [100, 150, 200],
      paymentDropdowns: {},
      kivra_status_options: [
        { text: this.$t('INVOICE.KIVRA_STATUSES.NONE'), value: 'NONE' },
        { text: this.$t('INVOICE.KIVRA_STATUSES.CREATED'), value: 'CREATED' },
        { text: this.$t('INVOICE.KIVRA_STATUSES.FAILED'), value: 'FAILED' },
        { text: this.$t('INVOICE.KIVRA_STATUSES.NOT_SSN'), value: 'NOT_SSN' }
      ],
      payment_options: [
        { text: this.$t('INVOICE.PAID'), value: 'PAID' },
        { text: this.$t('INVOICE.NOT_PAID'), value: 'NOT_PAID' },
        { text: this.$t('INVOICE.PARTIALLY_PAID'), value: 'PARTIALLY_PAID' }
      ],
      printed_options: [
        { text: this.$t('INVOICE.PRINTED'), value: 1 },
        { text: this.$t('INVOICE.NOT_PRINTED'), value: 0 },
        { text: this.$t('COMMON.ALL'), value: null }
      ],
      filters: {
        created_from: null,
        created_to: null,
        dateFilterFrom: '',
        dateFilterTo: '',
        paymentStatusFilter: '',
        printedFilter: ''
      },
      filteredItems: [],
      selected: [],
      copy_rows: [],
      fields: [

        {
          key: 'invoice_id',
          label: '#',
          sortable: true,
          tdClass: 'td-short4',
          thClass: 'td-short4'
        },
        {
          key: 'name',
          label: this.$t('INVOICE.RECIPIENT'),
          sortable: false,
          formatter: (_, __, item) => {
            if (item.member && item.member.invoice_firstname && item.member.invoice_lastname) {
              return `(M) ${item.member.invoice_firstname} ${item.member.invoice_lastname}`;
            } else if (item.member && item.member.firstname && item.member.lastname) {
              return `(M) ${item.member.firstname} ${item.member.lastname}`;
            } else if (item.receiving_company) {
              return `(F) ${item.receiving_company.name}`
            }
            return '';
          },
          sortByFormatted: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          key: 'total_sek',
          label: this.$t('INVOICE.TOTAL_SEK'),
          sortable: true,
          tdClass: 'td-short3',
          thClass: 'td-short3'
        },
        {
          key: 'ocr',
          label: 'OCR',
          sortable: true,
          tdClass: 'td-short3',
          thClass: 'td-short3'
        },
        {
          key: 'printed',
          label: this.$t('INVOICE.PRINTED'),
          sortable: true,
          formatter: (_, __, item) => {
            return item.printed ? this.$t('COMMON.YES') : this.$t('COMMON.NO');
          },
          tdClass: 'td-short4',
          thClass: 'td-short4'
        },
        {
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
          sortable: true,
          formatter: (_, __, item) => {
            return dayjs(item.created_at).format('YYYY-MM-DD');
          },
          sortByFormatted: true,
          tdClass: 'td-short4',
          thClass: 'td-short4'
        },
        {
          key: 'paid_at',
          label: this.$t('INVOICE.PAID_AT'),
          sortable: true,
          formatter: (_, __, item) => {
            return item.paid_at ? dayjs(item.paid_at).format('YYYY-MM-DD') : '';
          },
          sortByFormatted: true,
          tdClass: 'td-short5',
          thClass: 'td-short5'
        },
        {
          key: 'payment_status',
          label: this.$t('INVOICE.STATUS'),
          sortable: true,
          sortByFormatted: true,
          tdClass: 'td-short4',
          thClass: 'td-short4'
        },
        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>



.error-info-text {
  color: rgb(255, 84, 84);
  font-style: italic;
}

</style>
