<template>

  <div>

    <BgMaxEditModal
      ref="bgmax-edit-modal"
      @on_match_bgmax_to_invoice="on_match_bgmax_to_invoice"
    />


    <div class="card card-custom card-stretch mt-4">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column"></h3>

        <div class="card-toolbar">

        </div>

      </div>

      <div class="card-body pt-0 table-responsive" style="overflow: visible;">

        <b-row>
          <b-col xl="3" md="6" sm="12">
            <b-form-group
              id="input-group-created_from"
              :label="$t('PAGES.RENEWS.CREATED_FROM')"
              label-for="input-created_from"
            >
              <memlist-date-picker v-model="filters.created_from"></memlist-date-picker>
            </b-form-group>
          </b-col>
          <b-col xl="3" md="6" sm="12">
            <b-form-group
              id="input-group-created_to"
              :label="$t('PAGES.RENEWS.CREATED_TO')"
              label-for="input-created_to"
            >
              <memlist-date-picker v-model="filters.created_to"></memlist-date-picker>
            </b-form-group>
          </b-col>

        </b-row>

        <b-row class="mb-4">
          <b-col lg="6" sm="12">
            <b-form-input
              id="filter-input"
              v-model="filters.text"
              type="search"
              style="max-width:621px"
              :placeholder="$t('COMMON.SEARCH')"
            ></b-form-input>
          </b-col>

        </b-row>

        <b-table
          id="invoice-table"
          style="width: 100%; table-layout: fixed;"
          class="table-striped"
          :fields="fields"
          :filter="filters"
          :items="items"
          :per-page="per_page"
          :current-page="current_page"
          head-variant="light"
          ref="invoiceTable"
          @filtered="on_filtered"
          @sort-changed="on_sort_changed"
        >
          <template #cell(actions)="row">
            <div class="justify-content-end d-flex">


              <a class="btn btn-icon btn-light btn-sm mr-3" @click.prevent="select_bgmax_error_clicked(row.item)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                  <!--end::Svg Icon-->
                </span>
              </a>
              <a
                href="#"
                class="btn btn-icon btn-light btn-sm"
                @click.prevent="delete_bgmax_error_clicked(row.item)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </div>
          </template>

          <template #cell(selected)="row">
            <!--<v-simple-checkbox
              v-model="row.item.selected"
            ></v-simple-checkbox>-->
          </template>

        </b-table>

      </div>

    </div>

  </div>



</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import dayjs from 'dayjs';

import { mapGetters } from 'vuex';
import isBetween from 'dayjs/plugin/isBetween';
import BgMaxEditModal from '@/view/pages/ml/invoices/BgMaxEditModal.vue';

dayjs.extend(isBetween);

export default {
  name: 'bgmax-error-table',
  mixins: [ toasts ],
  props: ['items'],
  emits: ['on_bgmax_matched', 'deleted', 'filters_changed'],
  components: {
    BgMaxEditModal
  },
  computed: {
    ...mapGetters(['currentPeriodId', 'periods']),
    currentPeriod() {
      return this.periods.find(period => period.id === this.currentPeriodId);
    }
  },
  mounted() {

  },
  watch: {
    'filters.created_from'() {
      const payment_status = this.filters.payment_status ? this.filters.payment_status.code : null;
      this.$emit('filters_changed', { payment_status: payment_status, created_from: this.filters.created_from, created_to: this.filters.created_to });
    },
    'filters.created_to'() {
      const payment_status = this.filters.payment_status ? this.filters.payment_status.code : null;
      this.$emit('filters_changed', { payment_status: payment_status, created_from: this.filters.created_from, created_to: this.filters.created_to });
    },
    'filters.payment_status'() {
      const payment_status = this.filters.payment_status ? this.filters.payment_status.code : null;
      this.can_invoice = payment_status === 'NOT_PAID';
      this.$emit('filters_changed', { payment_status: payment_status, created_from: this.filters.created_from, created_to: this.filters.created_to });
    },
    'filters.text'() {
      this.$emit('filters_changed', { text: this.filters.text });
    },
    currentPeriod() {

    }
  },
  methods: {

    async on_match_bgmax_to_invoice(bgmax_id, invoice_id) {
      console.log('connecting bgmax ' + bgmax_id + ' to ' + invoice_id);

      try {
        const res = await axios.post(`/invoice/connect/${invoice_id}/bgmax-error/${bgmax_id}`);

        if (res.status === 200) {

          const bgmax = res.data.bgmax_error;

          if (bgmax.status === 'MATCHED') {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.MATCHED'));
          }

          this.$emit('on_bgmax_matched', bgmax);

          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_MATCH'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_MATCH'));
      }
    },

    on_filtered(filteredItems) {
      this.filteredItems = filteredItems;
      this.allSelected = false;
    },


    on_sort_changed(sortChanged) {

      if (sortChanged.sortBy === '') {
        this.orderBy = undefined;
      }
      else {
        this.orderBy = {

        };

        this.orderBy[sortChanged.sortBy] = sortChanged.sortDesc ? 'desc' : 'asc';
      }
    },

    get_error(status) {
      switch (status) {
        case 'UNMATCHED': {
          return this.$t('INVOICE.BGMAX_ERROR_ERROR.UNMATCHED');
        }
        case 'ALREADY_PAID': {
          return this.$t('INVOICE.BGMAX_ERROR_ERROR.ALREADY_PAID');
        }
        case 'UNKNOWN': {
          return this.$t('INVOICE.BGMAX_ERROR_ERROR.UNKNOWN');
        }
      }

      return status;
    },

    get_status(status) {
      switch (status) {
        case 'NONE': {
          return this.$t('INVOICE.BGMAX_ERROR_STATUS.NONE');
        }
        case 'MATCHED': {
          return this.$t('INVOICE.BGMAX_ERROR_STATUS.MATCHED');
        }
        case 'DELETED': {
          return this.$t('INVOICE.BGMAX_ERROR_STATUS.DELETED');
        }
        case 'IGNORE': {
          return this.$t('INVOICE.BGMAX_ERROR_STATUS.IGNORE');
        }
      }

      return status;
    },

    select_bgmax_error_clicked(item) {

      this.bgmax = item;

      this.$refs['bgmax-edit-modal'].show(this.bgmax);
    },

    async delete_bgmax_error_clicked(bgmax_record) {
      try {
        const res = await axios.delete(`/invoice/bgmax/error/${bgmax_record.id}`);

        if (res.status === 204) {

          this.toastr('success', this.$t('COMMON.OK'), this.$t('INVOICE.MATCH_DELETED'));

          this.$emit('deleted', bgmax_record);
          return;
        }

      }
      catch (err) {
        console.error('delete_bgmax_error_clicked error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_DELETE_MATCH'));

    },

  },
  data() {
    return {
      bgmax: null,
      totalRows: 0,
      orderBy: undefined,
      allSelected: false,
      can_invoice: false,
      current_page: 1,
      per_page: 100,
      options: [100, 150, 200],
      paymentDropdowns: {},
      paymentStatuses: [
        { label: this.$t('INVOICE.PAID'), code: 'PAID' },
        { label: this.$t('INVOICE.NOT_PAID'), code: 'NOT_PAID' },
        { label: this.$t('INVOICE.PARTIALLY_PAID'), code: 'PARTIALLY_PAID' }
      ],
      printedStatus: [
        { label: this.$t('INVOICE.PRINTED'), code: 1 },
        { label: this.$t('INVOICE.NOT_PRINTED'), code: 0 },
        { label: this.$t('COMMON.ALL'), code: null }
      ],
      filters: {
        created_from: null,
        created_to: null,
        dateFilterFrom: '',
        dateFilterTo: '',
        paymentStatusFilter: '',
        printedFilter: ''
      },
      filteredItems: [],
      selected: [],
      copy_rows: [],
      fields: [
        {
          key: 'selected',
          label: '',
          sortable: false,
        },
        {
          key: 'amount',
          label: this.$t('INVOICE.AMOUNT'),
          sortable: true,
        },
        {
          key: 'ocr',
          label: 'OCR',
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
          sortable: true,
          formatter: (_, __, item) => {
            return dayjs(item.created_at).format('YYYY-MM-DD');
          },
          sortByFormatted: true,
        },
        {
          key: 'paid_at',
          label: this.$t('INVOICE.PAID_AT'),
          sortable: true,
          formatter: (_, __, item) => {
            return item.paid_at ? dayjs(item.paid_at).format('YYYY-MM-DD') : '';
          },
          sortByFormatted: true,
        },
        {
          key: 'error',
          label: this.$t('INVOICE.ERROR'),
          sortable: true,
          formatter: (_, __, item) => {
            return this.get_error(item.error);
          },
          sortByFormatted: true,
        },
        {
          key: 'status',
          label: this.$t('INVOICE.STATUS'),
          sortable: true,
          formatter: (_, __, item) => {
            return this.get_status(item.status);
          },
          sortByFormatted: true,
        },

        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>




:deep .td-short {
  width: 32px;
}

:deep .td-short2 {
  width: 56px;
}

:deep .td-short3 {
  width: 76px;
}

:deep .td-short4 {
  width: 96px;
}

:deep .td-short5 {
  width: 116px;
}


:deep td {
  vertical-align: middle !important;
}

@media screen and (max-width: 768px) {
  #multiple-invoices-btn {
    margin-bottom: 2rem;
  }

  .date-time-picker {
    margin: 0;
  }

  .filter-box {
    margin-top: 1rem;
    padding: 0 !important;
  }
}
#status-filter {
  width: 200px;
}


.v-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 4px 0 !important;
}

</style>
